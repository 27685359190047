import { useEffect, memo, useRef } from "react";
import manager from "./manager";
import { useDispatch } from "react-redux";

import { setUser } from "../store/app";

import UserModel from "../models/User";

import * as Sentry from "@sentry/react";

import pluralizeJs from "pluralize";

import { storeJSON, getJSON } from "../utils/LocalDB";

const UserChannel = memo(({ username, userId, authToken }) => {
  const dispatch = useDispatch();

  const connectedTo = useRef();
  const lastProcessedTimestamp = useRef(0);
  const currentActivity = useRef({});
  const grouperTimeoutId = useRef(null);
  const firstLoad = useRef(false);

  // getJSON(`spaces`, `last-user-activity-at`, (data) => {
  //   lastProcessedTimestamp.current = data ? data.time : 0;
  // });

  // getJSON(`spaces`, `user-activity`, (data) => {

  //   currentActivity.current = data && data.activity
  //     ? data
  //     : { activity: [], today: { date: 0, ids: [] } };

  //   const todayDate = new Date().setHours(0, 0, 0, 0);
  //   if (
  //     currentActivity.current.today.date > 0 &&
  //     currentActivity.current.today.date < todayDate
  //   ) {
  //     currentActivity.current.today.date = todayDate;
  //     currentActivity.current.today.ids = [];
  //   }
  // });

  useEffect(() => {
    if (!username) {
      return;
    }

    if (connectedTo.current == username) {
      return;
    }

    const messageCallback = (data) => {
      console.log(
        `SOCKETS - > Message received to channel ${getChannelName()}: ${JSON.stringify(
          data
        )}`
      );

      const { action, id, slug } = data;

      connectedTo.current = username;

      if (action == "user") {
        if (slug == username || id == username) {
          onFetchUser();
        }
      }
    };

    const channelName = getChannelName();

    manager.subscribe(channelName, messageCallback);

    onFetchUser();

    return () => {
      manager.unsubscribe(channelName);
      connectedTo.current = null;
    };
  }, [username]);

  const getChannelName = () => {
    return `user_${username}`;
  };

  const onFetchUser = () => {
    UserModel.onAuth(
      userId,
      (data) => {
        window.$currentUserId = data.id;
        window.$currentName = data.name;
        window.$currentUserAuthToken = data.authentication_token;
        window.$currentUserId = data.id;
        window.$currentUsername = data.username;
        window.$currentEmail = data.email;
        window.$currentAccountType = data.account_type;
        window.$videoUploadKey = data.video_upload_key;
        window.$currentUserIsAdmin = data.is_admin;
        window.$currentUserAvatar =
          data.avatar && data.avatar.url ? data.avatar.url : DefaultAvatar;
        window.$videoUploadKey = data.video_upload_key;
        Sentry.setContext("user", {
          id: data.id,
          username: data.username,
        });

        if (window.hj) {
          window.hj("identify", data.username, {});
        }

        let auxActivity = [...data.activity];
        data.activity = [];
        if (!firstLoad.current){
          dispatch(setUser({ ...data }));
        }

        if (grouperTimeoutId.current){
          clearTimeout(grouperTimeoutId.current)
        }

        grouperTimeoutId.current = setTimeout(() => {
          const oldActivities = [...auxActivity[1]];
          oldActivities.forEach((notification) => {
            notification.is_seen = true;
          });
  
          auxActivity = [
            ...groupActivity(auxActivity[0]),
            ...oldActivities,
          ];
  
          data.activity = auxActivity;
          dispatch(setUser({ ...data }));
        }, firstLoad.current ? 0 : 1000)

        firstLoad.current = true

      },
      (data) => {}
    );
  };

  const groupActivity = (activity) => {
    const tmplastProcessedTimestamp = lastProcessedTimestamp.current;

    let groupedActivity = {};
    const startTime = performance.now();
    try {
      groupedActivity = activity.reduce((acc, notification) => {
        const key = `${notification.notification_type}_${notification.item_id}`;

        // Initialize the group if it doesn't exist
        if (!acc[key]) {
          acc[key] = {
            id: notification.id,
            notification_type: notification.notification_type,
            action: notification.action,
            raw_created_at: notification.created_at,
            created_at: timeAgo(new Date(notification.created_at)),
            is_seen: notification.is_seen,
            item_type: notification.item_type,
            item_id: notification.item_id,
            count: 1,
            not_seen_count: notification.is_seen ? 0 : 1,
            not_seen: notification.is_seen ? [] : [notification.id],
            meta_data: [],
            slug: notification.meta_data ? notification.meta_data.slug : "",
            pod_message_id: notification.meta_data
              ? notification.meta_data.pod_message_id
              : "",
            reflection_id: notification.meta_data
              ? notification.meta_data.reflection_id
              : "",
            parents_slugs: notification.meta_data
              ? notification.meta_data.parents_slugs
              : [],
            title: notification.meta_data
              ? notification.meta_data.item_name
              : "",
            subtitle: notification.meta_data
              ? notification.meta_data.top_parent_name
              : "",
            is_direct: notification.meta_data
              ? notification.meta_data.is_direct
              : false,
            url: notification.meta_data ? notification.meta_data.url : "",
            created_at: timeAgo(new Date(notification.created_at)),
            pod_messages: [],
            parents: [],
          };
        }

        if (
          new Date(notification.raw_created_at) > new Date(acc[key].raw_created_at)
        ) {
          acc[key].raw_created_at = notification.created_at;
          acc[key].created_at = timeAgo(new Date(notification.created_at));
        }

        acc[key].is_seen = acc[key].is_seen && notification.is_seen;
        acc[key].meta_data[notification.id] = notification.meta_data;
        acc[key].count++;
        if (!notification.is_seen) {
          acc[key].not_seen_count++;
          acc[key].not_seen.push(notification.id);
        }

        return acc;
      }, {});
    } catch {
      groupedActivity = {};
    }

    const endTime = performance.now();
    const elapsedTime = endTime - startTime;

    console.log(`Time taken grouping: ${elapsedTime} milliseconds`);

    return buildActivity(groupedActivity);
  };

  const buildActivity = (groupedActivity) => {
    const startTime = performance.now();
    const activityArray = Object.values(groupedActivity);

    for (const notification of activityArray) {
      const usernamesArr = new Set();
      const maxUsers = 3;

      if (notification.not_seen_count > 0) {
        for (const notificationId of notification.not_seen) {
          const username = notification.meta_data[notificationId].actor_name;
          usernamesArr.add(username);
          if (usernamesArr.size >= maxUsers) {
            break;
          }
        }
      } else {
        for (const meta_data of Object.values(notification.meta_data)) {
          const username = meta_data.actor_name;
          usernamesArr.add(username);
          if (usernamesArr.size >= maxUsers) {
            break;
          }
        }
      }
      const newArr = Array.from(usernamesArr);
      const usernames = newArr.join(", ");
      const extras =
      usernamesArr.size > 2
      ? ` and ${usernamesArr.size - 2} other ${pluralizeJs("person", usernamesArr.size - 2)}`
      : "";

      if (notification.notification_type == "reflection") {
        const action = "posted";
        const count = notification.not_seen_count > 0
        ? notification.not_seen_count
        : notification.count
        if (notification.action != "mention") {
          notification.content = `${usernames}${
            extras ? extras + " " : ""
          } ${action} ${count} ${pluralize(
            count,
            "reflection"
          )} on a message`;
        } else {
          notification.content = `${usernames} mentioned you in a reflection`;
        }
      } else if (notification.notification_type == "liked_reflection") {
        const action = "liked";
        const count = notification.not_seen_count > 0
        ? notification.not_seen_count
        : notification.count
        notification.content = `${usernames}${
          extras ? extras + " " : ""
        } ${action} your ${pluralize(
          count,
          "reflection"
        )}`;
      } else if (notification.notification_type == "liked_pod_message") {
        const action = "liked";
        const count = notification.not_seen_count > 0
        ? notification.not_seen_count
        : notification.count
        notification.content = `${usernames}${
          extras ? extras + " " : ""
        } ${action} your ${pluralize(
          count,
          "messages"
        )}`;
      } else if (notification.notification_type == "liked_pod") {
        const action = "liked";
        notification.content = `${usernames}${
          extras ? extras + " " : ""
        } ${action} your post`;
      } else if (notification.notification_type == "pod_member") {
        const action = notification.action;
        notification.content = `You were ${action} to ${notification.title}`;
      } else if (notification.notification_type == "pod_activity") {
        const action = "joined";
        notification.content = `${usernames}${
          extras ? extras + " " : ""
        } ${action}`;
      } else if (notification.notification_type == "parent_activity") {
        const action = "created";
        notification.content = `${usernames}${
          extras ? extras + " " : ""
        } ${action} a new post`;
      } else {
        const action = "posted";
        const count = notification.not_seen_count > 0
        ? notification.not_seen_count
        : notification.count
        if (notification.action != "mention") {
          notification.content = `${usernames}${
            extras ? extras + " " : ""
          } ${action} ${count} ${pluralize(
            count,
            "message"
          )}`;
        } else {
          notification.content = `${usernames} mentioned you in a message`;
        }
      }

     
    }

    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    console.log(`Time taken building: ${elapsedTime} milliseconds`);
    // storeGroupedActivities(activityArray);
    
    return activityArray;
  };

  const pluralize = (count, noun) => {
    if (noun == "person"){
      return "people"
    }
    return pluralizeJs(noun, count);
  };

  const timeAgo = (date) => {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;

    if (secondsPast < 60) {
      const time = parseInt(secondsPast);
      return time + ` ${pluralize(time, "second")} ago`;
    }
    if (secondsPast < 3600) {
      // less than an hour
      const time = parseInt(secondsPast / 60);
      return time + ` ${pluralize(time, "minute")} ago`;
    }
    if (secondsPast <= 86400) {
      // less than a day
      const time = parseInt(secondsPast / 3600);
      return time + ` ${pluralize(time, "hour")} ago`;
    }
    if (secondsPast <= 2592000) {
      // less than a month
      const time = parseInt(secondsPast / 86400);
      return time + ` ${pluralize(time, "day")} ago`;
    }
    if (secondsPast <= 31536000) {
      // less than a year
      const time = parseInt(secondsPast / 2592000);
      return time + ` ${pluralize(time, "month")} ago`;
    }

    const time = parseInt(secondsPast / 31536000);
    return time + ` ${pluralize(time, "year")} ago`;
  };

  const storeGroupedActivities = (groupedActivities) => {
    // storeJSON("spaces", `user-activity`, {
    //   activity: groupedActivities.slice(0, 30),
    //   today: currentActivity.current.today,
    // });
  };

  const updatelastProcessedTimestamp = (timestamp) => {
    // storeJSON("spaces", `last-user-activity-at`, { time: timestamp });
  };

  return null;
});

export default UserChannel;
