import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";

import { getEnv } from "../../../utils/Host";

import {
  Tabs,
  SegmentedControl,
  TextInput,
  Textarea,
  Button,
  Stack,
  Box,
  SimpleGrid,
  ScrollArea,
  Input,
  Image,
  MantineProvider,
  useMantineTheme,
  Select,
  Center,
  Loader,
} from "@mantine/core";

import axios from "axios";

import SERVICES from "../collections/embedRegex";

import { setTiptapSidemenu } from "../../../store/app";
import { sutraTheme } from "../../../collections/sutraTheme";

import { v4 as uuidv4 } from "uuid";

import Broadcaster from "../../../helpers/Broadcaster";
import MediaFileModel from "../../../models/MediaFile";
import SpaceModel from "../../../models/Space";

import PremiumModal from "../../app/PremiumModal";

import * as FilePond from "filepond";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
FilePond.registerPlugin(FilePondPluginImageResize);
FilePond.registerPlugin(FilePondPluginImageTransform);

let unsplashSearch = null;

export default function Uploader(props) {
  const space = useSelector((state) => state.space);
  const dispatch = useDispatch();
  const theme = useMantineTheme();
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [uploadType, setUploadType] = useState("image");
  const [embedType, setEmbedType] = useState("link");
  const [embedLink, setEmbedLink] = useState("");
  const [embedCode, setEmbedCode] = useState("");
  const [zoomData, setZoomData] = useState({ loading: true });
  const [zoomUserId, setZoomUserId] = useState("");
  const [zoomMeetingId, setZoomMeetingId] = useState("");
  const [unsplashImages, setUnsplashImages] = useState([]);
  const [canUseZoom, setCanUseZoom] = useState([]);
  const [opened, setOpened] = useState([]);
  const [selectedUnsplashImage, setSelectedUnsplashImage] = useState(null);

  const filePond = useRef();
  const imageInput = useRef();
  const lastZoomUser = useRef();

  useEffect(() => {
    return () => {
      filePond.current = null;
    };
  }, []);

  useEffect(() => {
    setCanUseZoom(
      space && space.permissions && space.permissions.can_use_zoom_recordings
    );
  }, [space]);

  useEffect(() => {
    if (props.uploadType) {
      setUploadType(props.uploadType);
      if (props.uploadType == "video") {
        setActiveTab(1);
      } else if (props.uploadType == "embed") {
        setActiveTab(0);
      } else if (props.uploadType != "image") {
        // Needed to do a double tab change for some reason!!
        setActiveTab(1);
        setTimeout(() => {
          setActiveTab(0);
        }, 100);
      }
    }

    if (unsplashImages.length == 0) {
      getUnspslahPhotos();
    }
  }, [props]);

  useEffect(() => {
    if (uploadType == "image" && !filePond.current) {
      initFilePond();
    }
    if (uploadType == "zoom") {
      if (!zoomData.connected){
        onFetchZoomData();
      }
    }
  }, [uploadType]);

  useEffect(() => {
    if (uploadType == "zoom" && lastZoomUser.current != zoomUserId) {
      onFetchZoomData();
    }
  }, [zoomUserId]);

  const getAcceptedTypes = () => {
    if (uploadType == "image") {
      return "image/*";
    } else if (uploadType == "video") {
      return ".mp4,.avi,.mkv,.webm,.mov,.yuv";
    } else if (uploadType == "audio") {
      return "audio/*";
    } else if (uploadType == "file") {
      return "pdf,doc,docx,xls,xlsx,ppt,pptx";
    } else {
      return null;
    }
  };

  const createNode = (type, url, extras = {}) => {
    // const {from, to} = window.$tiptapLastSelectionRange
    // props.editor.chain().setTextSelection({ from: to, to: to }).enter().run()

    if (props.action == "update") {
      props.editor.commands.updateAttributes(type, { src: url, ...extras });
    } else {
      if (type == "image") {
        props.editor
          .chain()
          .addNewEmptyLine()
          .setImage({
            src: url,
            ...extras,
            textAlign: !props.notMain ? "center" : "left",
            radius: "with-radius",
            resizeableWidth: props.context == "inline" ? "100%" : "100%",
          })
          .run();
      } else if (type == "video") {
        props.editor
          .chain()
          .addNewEmptyLine()
          .setVideo({
            src: url,
            ...extras,
            textAlign: !props.notMain ? "center" : "left",
            resizeableWidth: props.context == "inline" ? "100%" : "100%",
            radius: "with-radius",
          })
          .run();
      } else if (type == "audio") {
        props.editor
          .chain()
          .addNewEmptyLine()
          .setAudio({
            src: url,
            ...extras,
            textAlign: !props.notMain ? "center" : "left",
            resizeableWidth: props.context == "inline" ? "100%" : "100%",
          })
          .run();
      } else if (type == "file") {
        props.editor
          .chain()
          .addNewEmptyLine()
          .setFile({
            src: url,
            ...extras,
            textAlign: "left",
            displayAs: "link",
          })
          .run();
      } else {
        props.editor
          .chain()
          .addNewEmptyLine()
          .setFile({
            src: url,
            ...extras,
            textAlign: !props.notMain ? "center" : "left",
            resizeableWidth: props.context == "inline" ? "100%" : "100%",
            displayAs: "link",
          })
          .run();
      }
    }

    if (!props.notMain) {
      window.$tiptapLastSelectionRange = {
        from: props.editor.view.state.selection.from,
        to: props.editor.view.state.selection.to,
      };
      dispatch(setTiptapSidemenu({ opened: "configuration" }));
    }

    props.onSave();
  };

  const getExtension = (path) => {
    const baseName = path.split(/[\\/]/).pop();
    const pos = baseName.lastIndexOf(".");
    if (baseName === "" || pos < 1) {
      return "";
    }
    return baseName.slice(pos + 1).toLowerCase();
  };

  const getEmbedLinkType = (embedLink) => {
    const ext = getExtension(embedLink);

    if ("pdf,doc,docx,xls,xlsx,ppt,pptx".indexOf(ext) >= 0) {
      return "file";
    } else if ("png,jpg,jpeg,gif".indexOf(ext) >= 0) {
      return "image";
    } else if ("mp3,ogg,mpeg".indexOf(ext) >= 0) {
      return "audio";
    } else if ("video".indexOf(ext) >= 0) {
      return "mp4,mov,mkv,yuv";
    }
    return "image";
  };

  const getEmbedUrl = (code) => {
    let validService = null;
    const services = [];
    for (let i = 0; i < Object.entries(SERVICES).length; i++) {
      services.push(Object.entries(SERVICES)[i][0]);
    }

    for (let i = 0; i < services.length; i++) {
      if (SERVICES[services[i]].regex.test(code)) {
        validService = SERVICES[services[i]];
        break;
      }
    }

    if (!validService) {
      return null;
    }

    const {
      regex,
      embedUrl,
      width,
      height,
      id = (ids) => ids.shift(),
      splice,
    } = validService;
    let result = "";
    let embed = "";

    if (splice == "iframe") {
      result = regex.exec(code);

      embed = result[3];
    } else {
      result = regex.exec(code).slice(1);
      embed = embedUrl.replace(/<%= remote_id %>/g, id(result));

      if (splice == 2) {
        result = regex.exec(code).slice(3);
        embed = embed.replace(/<%= remote_id2 %>/g, id(result));
      }
    }

    return [embed, id(result)];
  };

  const saveEmbed = () => {
    // const {from, to} = window.$tiptapLastSelectionRange
    // props.editor.commands.setTextSelection({ from: to, to: to })
    if (embedType == "link") {
      // Get extension and save block
      const url = getEmbedUrl(embedLink);
      if (url && url.length > 0) {
        if (props.action == "update") {
          props.editor.commands.updateAttributes("embed", {
            src: url[0],
            videoId: url[1],
          });
        } else {
          props.editor
            .chain()
            .setEmbed({
              videoId: url[1],
              src: url[0],
              textAlign: !props.notMain ? "center" : "left",
              resizeableWidth: props.context == "inline" ? "100%" : "100%",
              radius: "with-radius",
            })
            .run();
        }
        props.onSave();
        onCreateEmbedMediaFile(url[0]);
      } else {
        createNode(getEmbedLinkType(embedLink), embedLink);
      }
    } else {
      const url = getEmbedUrl(embedCode);
      if (props.action == "update") {
        props.editor.commands.updateAttributes("embed", { src: url[0] });
      } else {
        if (url && url.length > 0) {
          props.editor
            .chain()
            .setEmbed({
              src: url[0],
              videoId: url[1],
              radius: "with-radius",
              resizeableWidth: "100%",
            })
            .run();
        }
      }
      props.onSave();
      onCreateEmbedMediaFile(url[0], url[1]);
    }
  };

  const onCreateEmbedMediaFile = (original_url, embed_id) => {
    const mediaFile = {
      original_url: original_url,
      url: original_url,
      upload_type: "embed",
      slug: window.$currentSlug,
      embed_id: embed_id
    };

    MediaFileModel.onCreate(
      mediaFile,
      (data) => {
        console.log("MEDIA FILE STORED");
      },
      (data) => {}
    );
  }

  const onUnsplashSearch = (event) => {
    if (unsplashSearch) {
      clearTimeout(unsplashSearch);
    }
    unsplashSearch = setTimeout(() => {
      unsplashSearch = null;

      getUnspslahPhotos(event.target.value);
    }, 2000);
  };

  const getUnspslahPhotos = (searchTerm) => {
    const url = searchTerm ? "/search/photos" : "/photos";
    const params = {
      client_id: "3y8CSIGwECl2nY2FDweClIN3oAATn_ifTTTEhh1V9FE",
      query: searchTerm ? searchTerm : null,
      page: 1,
      per_page: 30,
    };

    axios
      .get(`https://api.unsplash.com/${url}`, { params: params })
      .then((res) => {
        if (searchTerm) {
          setUnsplashImages(res.data.results);
        } else {
          setUnsplashImages(res.data);
        }
      })
      .catch((error) => {});
  };

  const saveUnsplashImage = (event) => {
    if (props.uploadToEndpoint) {
      props.uploadToEndpoint(selectedUnsplashImage);
      return;
    }
    createNode("image", selectedUnsplashImage);
  };

  const onOpenFileSelector = (event) => {
    try {
      event.target.children[0].click();
    } catch {}
  };

  const handleVideoFileInput = (e) => {
    const file = e.target.files[0];
    if (props.uploadToEndpoint) {
      props.uploadToEndpoint(file);
      return;
    }
    let extension = file.name.split(".").pop();
    if (extension) {
      extension = extension.toLowerCase();
    }
    const fileName = escapeFilename(file.name).replace(`.${extension}`, "");

    let videoId = `${uuidv4().replace(/-/g, "")}/${encodeURIComponent(
      fileName
    )}.${extension}`;
    let blockUrl = null;
    if (uploadType == "video") {
      const baseName = `${encodeURIComponent(
        uuidv4().replace(/-/g, "").replace(/_/g, "")
      )}_${window.$videoUploadKey}`;
      videoId = `${uuidv4().replace(/-/g, "")}/${baseName}.${extension}`;
      blockUrl = `https://sutra-video-output.s3.us-west-1.amazonaws.com/${baseName}.m3u8`;
    }

    console.log(`VIDEO NAME ${uploadType}: ${videoId}`);

    Broadcaster.send("new_uploadFile", document.body, {
      id: videoId,
      file: file,
      type: uploadType,
      status: "pending",
      space: {
        name: space.name,
        version: space.version,
        slug: space.slug,
      },
      trigger: "videoHandler",
      url: blockUrl,
      extension: extension,
    });

    const extras = {};

    if (uploadType == "file") {
      extras.name = file.name;
      extras.size = file.size;
      extras.extension = extension;
    }

    createNode(uploadType, blockUrl, {
      ...extras,
      uploader: {
        id: videoId,
        status: "pending",
        progress: 0,
        url: blockUrl,
      },
    });

    const mediaFile = {
      original_url: `https://sutra-froala.s3.us-west-1.amazonaws.com/uploads/${videoId}`,
      url:
        uploadType == "video"
          ? blockUrl
          : `https://sutra-froala.s3.us-west-1.amazonaws.com/uploads/${videoId}`,
      upload_type: uploadType,
      slug: window.$currentSlug,
    };

    MediaFileModel.onCreate(
      mediaFile,
      (data) => {
        console.log("MEDIA FILE STORED");
      },
      (data) => {}
    );
  };

  const escapeFilename = (filename) => {
    // Remove any leading or trailing spaces
    filename = filename.trim();

    // Replace any consecutive whitespace characters with a single space
    filename = filename.replace(/\s+/g, " ");

    // Replace any special characters with underscores
    filename = filename.replace(/[^0-9A-Za-z.\-]/g, "_");

    // Limit the length of the file name to 255 characters
    filename = filename.slice(0, 255);

    return filename;
  };

  const initFilePond = () => {
    const inputElement = imageInput.current;
    if (filePond.current) {
      return;
    }
    filePond.current = FilePond.create(inputElement, {
      imageResizeTargetWidth: 1080,
      imageResizeUpscale: false,
      imageTransformOutputQuality: 70,
      // add onaddfile callback
      onaddfile: (err, fileItem) => {},
      onpreparefile: (fileItem, output) => {
        // image.thumbnail = URL.createObjectURL(output);
      },
      server: {
        process: (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options
        ) => {
          if (file.type.indexOf("image") < 0) {
            return;
          }

          if (props.uploadToEndpoint) {
            props.uploadToEndpoint(file);
            return;
          }

          const extension = file.name.split(".").pop();
          const fileName = escapeFilename(file.name).replace(
            `.${extension}`,
            ""
          );

          const imageId = `${uuidv4().replace(/-/g, "")}/${encodeURIComponent(
            fileName
          )}.${extension}`;

          Broadcaster.send("new_uploadFile", document.body, {
            id: imageId,
            file: file,
            type: uploadType,
            status: "pending",
            space: {
              name: space.name,
              version: space.version,
              slug: space.slug,
            },
            trigger: "filepond",
          });

          createNode(uploadType, null, {
            uploader: {
              id: imageId,
              status: "pending",
              progress: 0,
            },
          });

          const mediaFile = {
            original_url: `https://sutra-froala.s3.us-west-1.amazonaws.com/uploads/${imageId}`,
            url: `https://sutra-froala.s3.us-west-1.amazonaws.com/uploads/${imageId}`,
            upload_type: "image",
            slug: window.$currentSlug,
          };

          MediaFileModel.onCreate(
            mediaFile,
            (data) => {
              console.log("MEDIA FILE STORED");
            },
            (data) => {}
          );
        },
      },
    });
  };

  const onFetchZoomData = () => {
    SpaceModel.onZoomData(
      space.slug,
      zoomUserId,
      (data) => {
        setZoomData({ ...data.zoom_data });
        if (data.zoom_data.users.length == 1) {
          lastZoomUser.current = data.zoom_data.users[0].value;
          setZoomUserId(data.zoom_data.users[0].value);
        }
      },
      (data) => {}
    );
  };

  const onConnectZoom = () => {
    if (!canUseZoom[0]) {
      setOpened([true, canUseZoom[1], canUseZoom[2]]);
      return;
    }

    if (getEnv() == "production") {
      window.location.href= `https://zoom.us/oauth/authorize?client_id=Iq_GKcCRhWRVRdVaE3W5Q&response_type=code&redirect_uri=https://sutra.co/api/v4/hooks/zoom/redirect?state=${space.slug}_usr_${window.$currentUserAuthToken}`;
    } else {
      window.location.href= `https://zoom.us/oauth/authorize?client_id=YK0Cx_LQ16AVBy0FFvaxQ&response_type=code&redirect_uri=https://sutra.ngrok.dev/api/v4/hooks/zoom/redirect?state=${space.slug}_usr_${window.$currentUserAuthToken}`;
    }
  };

  const onCreateZoom = () => {
    if (!canUseZoom[0]) {
      setOpened([true, canUseZoom[1], canUseZoom[2]]);
      return;
    }

    const uid = `${uuidv4().replace(/-/g, "")}`;
    const videoId = `zoom_recording_${zoomMeetingId}_${uid}`;
    const url = `https://sutra-video-output.s3.us-west-1.amazonaws.com/${videoId}.m3u8`;

    props.editor
      .chain()
      .addNewEmptyLine()
      .setVideo({
        id: uid,
        src: url,
        uploader: {
          id: `zoom/${videoId}.mp4`,
          status: "zoom-pending",
          progress: 0,
          url: url,
        },
        textAlign: !props.notMain ? "center" : "left",
        resizeableWidth: props.context == "inline" ? "100%" : "100%",
        radius: "with-radius",
      })
      .run();

    const mediaFile = {
      original_url: `https://sutra-froala.s3.us-west-1.amazonaws.com/uploads/zoom/${videoId}.mp4`,
      url: url,
      upload_type: "zoom-recording",
      slug: window.$currentSlug,
    };

    MediaFileModel.onCreate(
      mediaFile,
      (data) => {
        console.log("MEDIA FILE STORED");
      },
      (data) => {}
    );

    props.onSave();
  };

  return (
    <MantineProvider theme={sutraTheme}>
      <Box>
        <Tabs
          styles={{
            tabsList: {
              flexWrap: "nowrap",
            },
            tabActive: {
              fontWeight: "bold",
              color: `${theme.colors.dark[9]} !important`,
              borderBottomColor: `${sutraTheme.colors.sutrablue[4]} !important`,
            },
            tabLabel: { color: theme.colors.gray[7] },
          }}
          active={activeTab}
          onTabChange={setActiveTab}
        >
          {uploadType != "embed" && (
            <Tabs.Tab
              label={uploadType == "zoom" ? "Zoom recording" : "Upload"}
            >
              <Box>
                {uploadType == "image" ? (
                  <Button
                    radius="xl"
                    fullWidth
                    onClick={(event) => onOpenFileSelector(event)}
                  >
                    <input
                      ref={imageInput}
                      type="file"
                      style={{ display: "none" }}
                      accept={getAcceptedTypes()}
                    />
                    {`Select ${uploadType}`}
                  </Button>
                ) : uploadType == "zoom" ? (
                  <>
                    {zoomData.connected ? (
                      <>
                        {zoomData.users && zoomData.users.length > 1 && (
                          <Select
                            zIndex={100000}
                            label="Select a user"
                            placeholder="Pick one"
                            data={[...zoomData.users]}
                            value={zoomUserId}
                            onChange={setZoomUserId}
                            searchable
                            mb={20}
                          />
                        )}
                        <Select
                          zIndex={100000}
                          label="Select meeting"
                          placeholder="Pick one"
                          data={[...zoomData.meetings]}
                          value={zoomMeetingId}
                          onChange={setZoomMeetingId}
                          searchable
                        />
                        <Button
                          onClick={() => onCreateZoom()}
                          disabled={!zoomMeetingId}
                          mt={20}
                          radius="xl"
                        >
                          Create recording placeholder
                        </Button>
                      </>
                    ) : zoomData.loading ? (
                      <Center>
                        <Loader />
                      </Center>
                    ) : (
                      <Button onClick={() => onConnectZoom()} radius="xl">
                        Connect Zoom Account
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    radius="xl"
                    fullWidth
                    onClick={(event) => onOpenFileSelector(event)}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept={getAcceptedTypes()}
                      onChange={handleVideoFileInput}
                    />
                    {`Select ${uploadType}`}
                  </Button>
                )}

                {/* <Link to="/zoom/integration">Read more about zoom integration</Link> */}
              </Box>
            </Tabs.Tab>
          )}
          {!props.exclude ||
            (props.exclude &&
              props.exclude.indexOf("embed") < 0 &&
              uploadType != "zoom" && (
                <Tabs.Tab label="Embed">
                  <Box>
                    <Stack>
                      <SegmentedControl
                        value={embedType}
                        onChange={setEmbedType}
                        data={[
                          {
                            label: t("tiptap.components.uploader.label1"),
                            value: "link",
                          },
                          {
                            label: t("tiptap.components.uploader.label2"),
                            value: "code",
                          },
                        ]}
                      />
                      {embedType == "link" ? (
                        <TextInput
                          value={embedLink}
                          onChange={(event) => setEmbedLink(event.target.value)}
                          placeholder={t(
                            "tiptap.components.uploader.placeholder"
                          )}
                          label={t("tiptap.components.uploader.label3")}
                        />
                      ) : (
                        <Textarea
                          value={embedCode}
                          onChange={(event) => setEmbedCode(event.target.value)}
                          placeholder={t(
                            "tiptap.components.uploader.placeholder2"
                          )}
                          label={t("tiptap.components.uploader.label4")}
                        />
                      )}
                      <Button
                        radius="xl"
                        fullWidth
                        onClick={(event) => {
                          saveEmbed(event);
                        }}
                      >
                        {t("tiptap.components.uploader.saveEmbed")} {embedType}
                      </Button>
                    </Stack>
                  </Box>
                </Tabs.Tab>
              ))}
          {uploadType == "image" ? (
            <Tabs.Tab label="Unsplash">
              <Box>
                <Stack
                  style={{
                    height: "100%",
                  }}
                >
                  <Input
                    onChange={(event) => onUnsplashSearch(event)}
                    icon={<FontAwesomeIcon icon={solid("search")} />}
                    placeholder={t("tiptap.components.uploader.placeholder3")}
                  />
                  <ScrollArea
                    style={{
                      height: "100%",
                    }}
                  >
                    <SimpleGrid
                      cols={5}
                      style={{
                        height: props.unsplashHeight
                          ? props.unsplashHeight
                          : 140,
                      }}
                    >
                      {unsplashImages.map((image) => {
                        return (
                          <div
                            class={`${
                              selectedUnsplashImage == image.urls.regular
                                ? "unsplash-selected"
                                : "unsplash-img"
                            }`}
                            onClick={(event) =>
                              setSelectedUnsplashImage(image.urls.regular)
                            }
                          >
                            <Image
                              radius="md"
                              src={image.urls.small}
                              alt={image.description}
                            />
                          </div>
                        );
                      })}
                    </SimpleGrid>
                  </ScrollArea>
                  <Button
                    radius="xl"
                    fullWidth
                    disabled={!selectedUnsplashImage}
                    onClick={(event) => {
                      saveUnsplashImage(event);
                    }}
                  >
                    {t("tiptap.components.uploader.saveImage")}
                  </Button>
                </Stack>
              </Box>
            </Tabs.Tab>
          ) : (
            <></>
          )}
        </Tabs>
      </Box>
      <PremiumModal opened={opened} setOpened={setOpened} />
    </MantineProvider>
  );
}
