import React, { useState, useEffect } from "react";

import { Text, Box, Drawer, Divider, ScrollArea } from "@mantine/core";

import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";

import TiptapReadonly from "../../../tiptap/components/TiptapReadonly";

export default function Broadcast(props) {
  const { t, i18n } = useTranslation();
  const [broadcast, setBroadcast] = useState({ user: {} });

  const data = [
    {
      value: "active",
      label: t("spaces.settings.broadcast.membersType.active"),
    },
    {
      value: "pending",
      label: t("spaces.settings.broadcast.membersType.pending"),
    },
    {
      value: "interested",
      label: t("spaces.settings.broadcast.membersType.interested"),
    },
  ];

  useEffect(() => {
    if (props.broadcast) {
      setBroadcast(props.broadcast);
    }
  }, [props]);

  return (
    <Drawer
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title={
        <Text size="xl" weight="800">
          {`${t("spaces.settings.broadcast.sentOn")} `}
          {DateTime.fromSQL(broadcast.sent_on).toFormat("LLL d, yyyy, h:mm a")}{" "}
        </Text>
      }
      padding="xl"
      size={600}
    >
      <ScrollArea style={{ height: "100%" }}>
        <Text
          sx={{
            fontSize: 26,
            fontWeight: 300,
            marginBottom: 15,
          }}
        >
          {broadcast.title}
        </Text>

        <Divider mb={25} />

        <TiptapReadonly content={broadcast.content_json} />

        {Array.isArray(broadcast?.sent_to_users) && broadcast.sent_to_users.length > 0 && (
          <>
            <Divider mb={25} mt={25} />
            <Text weight={800}>
              Sent to:
              <Text weight={300}>{broadcast.sent_to_users.join(', ')}</Text>
            </Text>
          </>
        )}

        <Box mt={100} />
      </ScrollArea>
    </Drawer>
  );
}
